<template>
  <div>
    <div class="mb-6">
      <h2 class="custom-title-secondary mb-1">
        \o/ Agora precisamos de mais algumas informações
      </h2>
      <span class="custom-normal-text text-blue">
        Preencha as informações da empresa e do(s) sócio(s) para que possamos
        iniciar o seu processo
      </span>
    </div>
    <v-tabs
      :value="activeTab"
      :class="$style['custom-tab']"
      icons-and-text
      show-arrows
    >
      <v-tabs-slider :color="$style.mainGreen"></v-tabs-slider>

      <v-tab
        v-for="item in currentTablist"
        :key="item.id"
        :href="`#${item.id}`"
        :active-class="$style['custom-tab-active']"
        class="custom-subtitle-text"
        :to="item.goTo"
      >
        {{ item.name }}
        <v-icon
          large
          :color="colorIcon(item)"
          :class="item.incomplete ? $style['fa-beat'] : ''"
        >
          {{ item.incomplete ? 'warning' : item.icon }}
        </v-icon>
      </v-tab>
      <v-tab
        v-if="company.hasPartner"
        key="add"
        :href="`#add`"
        class="custom-subtitle-text"
        @click="addPartner()"
      >
        adicionar sócio
        <v-icon>add</v-icon>
      </v-tab>
    </v-tabs>
    <div class="ma-1 pt-2">
      <RouterView :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showAll: false,
      tabList: [],
    }
  },
  computed: {
    ...mapGetters({
      tabItems: 'formCompanyPartner/tabItems',
      company: 'company/company',
      forceValidationCompany: 'formCompanyPartner/forceValidationCompany',
      forceValidationPartners: 'formCompanyPartner/forceValidationPartners',
    }),
    currentTablist() {
      return this.tabItems
    },
    activeTab() {
      if (this.$route.name === 'partner') {
        let selectPartner = this.currentTablist.find(
          (item) => item.id == this.$route.params.id
        )
        return !!selectPartner ? `${selectPartner.id}` : ''
      }
      return 'company'
    },
  },
  methods: {
    async addPartner() {
      const newPartner = await this.$store.dispatch('partner/createPartner', {})
      this.$store.commit('formCompanyPartner/newTabItemPartner', newPartner.id)
      this.$router.push({ name: 'partner', params: { id: newPartner.id } })
    },
    colorIcon(item) {
      if (item.incomplete) return 'red darken-1'
      return this.activeTab == item.id ? '#008045' : ''
    },
  },
  async created() {
    await this.$store.dispatch('formCompanyPartner/getValidations')
    await this.$store.dispatch('company/getCurrentCompany')
    await this.$store.dispatch('partner/getAllPartners')
    this.$store.dispatch('matrimonialRegime/getAllMatrimonialRegimes')
    if (this.forceValidationCompany || this.forceValidationPartners)
      this.$store.dispatch('formCompanyPartner/forceValidation')
  },
}
</script>

<style lang="scss" module>
@import '@design';
.custom-tab {
  border: 1px solid #008045 !important;
}
.custom-tab-active {
  background-color: #ddfe54;
  color: #008045 !important;
}
.fa-beat {
  -webkit-animation: fa-beat 1s infinite linear;
  animation: fa-beat 1s infinite linear;
}
@-webkit-keyframes fa-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes fa-beat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
